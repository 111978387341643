<template>
  <div class="realName">
    <div class="realName-title">您还未进行实名认证，请先进行实名认证。</div>
    <div class="realName-form">
      <van-cell-group>
        <div class="realName-tip">姓名</div>
        <van-field v-model="login.name" placeholder="请输入姓名" />
        <div class="realName-tip">身份证号</div>
        <van-field v-model="login.idcard" type="idcard" placeholder="请输入身份证号" />
        <div class="realName-tip">手机号码</div>
        <van-field type="tel" v-model="login.mobile" :disabled="login.mobile" placeholder="请输入手机号码" />

        <div class="realName-tip">验证码</div>
        <van-field
          type="number"
          v-model="login.authCode"
          center
          clearable
          label=""
          placeholder="请输入短信验证码"
        >
          <van-button
            @click="getMessCode"
            slot="button"
            size="small"
            type="primary"
            >{{ sendBtn }}</van-button
          >
        </van-field>
      </van-cell-group>
    </div>
    <div class="realName-btn">
      <div class="realName-btn-cancel" @click="goBack">取消</div>
      <div class="realName-btn-submit" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import { sendAuthCodeByMobile, realname, getCurUserInfo, signature } from '@/util/api'
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      login: {
        name: "",
        idcard: "",
        mobile: "",
        authCode: "",
      },
      sendBtn: "发送验证码",
      sendTime: 60,

    };
  },
  computed: {
    ...mapState(['userInfo', 'signatureParam'])
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    getMessCode() {
      const { mobile } = this.login
      if (mobile) {
        if (this.sendTime < 60) {
          return false
        }
        sendAuthCodeByMobile({ captcha: '', mobilePhone: mobile }).then((res) => {
          console.log('res', res);
          const timer = setInterval(() => {
            this.sendTime -= 1;
            this.sendBtn = `${this.sendTime}s后重试`;
            if (this.sendTime === 0) {
              this.sendBtn = "发送验证码";
              this.sendTime = 60;
              clearInterval(timer);
            }
          }, 1000);
        })
      } else {
        this.$toast.fail("请输入手机号");
      }
    },
    submit() {
      const { name, idcard, authCode, mobile } = this.login
      realname({ name, idcard, authCode, mobile, realNamePurpose: 1 }).then(() => {
        getCurUserInfo().then((res) => {
          this.setUserInfo(res)
          // this.$router.push({ path: '/sign' })
          let signatureParam = {
            ...this.signatureParam,
            authCode: authCode
          }
          signature(signatureParam).then(() => {
            this.$router.push({ path: '/success' })
          }).catch(() => {
            this.$router.back(1)
          })
        })
      })
    },
    goBack() {
      this.$router.back(1)
    }
  },
  mounted() {
    this.login.mobile = this.userInfo.contact
  }
};
</script>

<style lang="less" scoped>
.realName {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 14px 16px 0;

  box-sizing: border-box;
  &-title {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #8b96a6;
    opacity: 1;
  }
  &-tip {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #8B96A6;
    opacity: 1;
    margin-top: 24px;
  }
  &-form {
    margin-top: 16px;
    /deep/.van-field__control:disabled {
      color: #323233;
    }
  }
  &-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.04);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    &-cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 164px;
      height: 44px;
      background: #F6F7F8;
      opacity: 1;
      border-radius: 30px;
      text-align: center;
      line-height: 44px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #464646;
      opacity: 1;

    }
    &-submit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 164px;
      height: 44px;
      background: #0067E1;
      opacity: 1;
      border-radius: 30px;
      text-align: center;
      line-height: 44px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      opacity: 1;
    }
  }
}
</style>